import '../../index.css';

import { Navbar, Container, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const NavigationBar = () => {
  //May have to have each conditional return a whole new <Nav> to make button seperate//
  return (
    <Navbar expand='lg' fixed='top' bg='black' variant='dark'>
      <Container>
        <Navbar.Brand as={Link} to='/'>
          <span className='nav-title' style={{ fontSize: 30, color: 'white' }}>
            Jaren Gisner
          </span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto'>
            <Nav.Link as={Link} to='/projects' style={{ color: 'white' }}>
              Projects
            </Nav.Link>
            <Nav.Link as={Link} to='/about' style={{ color: 'white' }}>
              About
            </Nav.Link>
            <Nav.Link as={Link} to='/contact' style={{ color: 'white' }}>
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
