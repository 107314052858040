import '../../index.css';
import { Container, Row, Col, Card, CardImg } from 'react-bootstrap';
//component imports
import { NavigationBar } from '../navigation-component/navigation-component';
import { FooterComponent } from '../footer-component/footer-component';

//image imports
import movieImg from '../../images/movie-react.png';
import movieAngular from '../../images/movie-angular.png';
import meetApp from '../../images/meet-app-pic.png';
import chatPicture from '../../images/chat-app-img2.png';
import backEndPic from '../../images/back-end-photo.png';
import groupsPic from '../../images/groups-pic.png'
import groupsApiPic from '../../images/groups-api-pic.png'

//icon imports
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ProjectsPage = () => {
  return (
    <>
      <Container fluid style={{ paddingBottom: '30%', overflowY: 'scroll' }} id='project-container-main'>
        <Row>
          <NavigationBar />
        </Row>
        <div style={{ marginTop: 50, marginBottom: 80 }} className='projects'>
          <Row className='justify-content-center' style={{ marginTop: 30 }}>
            <Col xs={12} sm={12} md={3} lg={3} id='col-card-last'>
              <Card id='card-block'>
                <CardImg src={groupsPic} />
                <Card.Title style={{ textAlign: 'center' }} id='title-text'>
                  JGroup
                </Card.Title>
                <Card.Text style={{ textAlign: 'center' }} id='sub-text'>
                    A social media application, with full features, such as likes, etc.
                </Card.Text>
                <div className='card-icon-container'>
                  <a
                    href='https://jgroup.netlify.app/'
                    target='_blank'
                    rel='noreferrer'
                    className='card-icon'
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <FontAwesomeIcon
                      icon={faGlobe}
                      style={{ color: 'black', fontSize: 30 }}
                    />
                  </a>
                  <a
                    href='https://github.com/jarengisner/groups-client'
                    target='_blank'
                    rel='noreferrer'
                    className='card-icon'
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <FontAwesomeIcon
                      icon={faGithub}
                      style={{ color: 'black', fontSize: 30 }}
                    />
                  </a>
                </div>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3} id='col-card-last'>
              <Card id='card-block'>
                <CardImg src={groupsApiPic} />
                <Card.Title style={{ textAlign: 'center' }} id='title-text'>
                  JGroup API
                </Card.Title>
                <Card.Text style={{ textAlign: 'center' }} id='sub-text'>
                  REST API supporting the jGroup client
                </Card.Text>
                <div className='card-icon-container'>
                  <a
                    href='https://github.com/jarengisner/groups-api'
                    target='_blank'
                    rel='noreferrer'
                    className='card-icon'
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <FontAwesomeIcon
                      icon={faGithub}
                      style={{ color: 'black', fontSize: 30 }}
                    />
                  </a>
                </div>
              </Card>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{ display: 'flex' }}
              id='col-card'
            >
              <Card id='card-block'>
                <CardImg src={backEndPic} />
                <Card.Title style={{ textAlign: 'center' }} id='title-text'>
                  Movie Findr API
                </Card.Title>
                <Card.Text style={{ textAlign: 'center' }} id='sub-text'>
                  API to serve movies and corresponding information
                </Card.Text>
                <div className='card-icon-container'>
                  <a
                    href='https://github.com/jarengisner/aws-movie-api'
                    target='_blank'
                    rel='noreferrer'
                    className='card-icon'
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <FontAwesomeIcon
                      icon={faGithub}
                      style={{ color: 'black', fontSize: 30 }}
                    />
                  </a>
                </div>
              </Card>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{ display: 'flex' }}
              id='col-card'
            >
              <Card id='card-block'>
                <CardImg src={movieImg} />
                <Card.Title style={{ textAlign: 'center' }} id='title-text'>
                  Movie-Findr - React
                </Card.Title>
                <Card.Text style={{ textAlign: 'center' }} id='sub-text'>
                  A React front end client using the movie-findr API
                </Card.Text>
                <div className='card-icon-container'>
                  <a
                    href='https://movie-findr.netlify.app/signup'
                    target='_blank'
                    rel='noreferrer'
                    className='card-icon'
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <FontAwesomeIcon
                      icon={faGlobe}
                      style={{ color: 'black', fontSize: 30 }}
                    />
                  </a>
                  <a
                    href='https://github.com/jarengisner/movie-findr-client'
                    target='_blank'
                    rel='noreferrer'
                    className='card-icon'
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <FontAwesomeIcon
                      icon={faGithub}
                      style={{ color: 'black', fontSize: 30 }}
                    />
                  </a>
                </div>
              </Card>
            </Col>
          </Row>
          <Row className='justify-content-center' style={{ marginTop: 20 }}>
            <Col xs={12} sm={12} md={3} lg={3} id='col-card'>
              <Card id='card-block'>
                <CardImg src={meetApp} />
                <Card.Title style={{ textAlign: 'center' }} id='title-text'>
                  Meet App
                </Card.Title>
                <Card.Text style={{ textAlign: 'center' }} id='sub-text'>
                  A meetup application that presents coding events from around
                  the world
                </Card.Text>
                <div className='card-icon-container'>
                  <a
                    href='https://jarengisner.github.io/meet-app/'
                    target='_blank'
                    rel='noreferrer'
                    className='card-icon'
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <FontAwesomeIcon
                      icon={faGlobe}
                      style={{ color: 'black', fontSize: 30 }}
                    />
                  </a>
                  <a
                    href='https://github.com/jarengisner/meet-app'
                    target='_blank'
                    rel='noreferrer'
                    className='card-icon'
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <FontAwesomeIcon
                      icon={faGithub}
                      style={{ color: 'black', fontSize: 30 }}
                    />
                  </a>
                </div>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3} id='col-card'>
              <Card id='card-block'>
                <CardImg src={chatPicture} />
                <Card.Title style={{ textAlign: 'center' }} id='title-text'>
                  Chat App
                </Card.Title>
                <Card.Text style={{ textAlign: 'center' }} id='sub-text'>
                  A real-time chat application using React Native
                </Card.Text>
                <div className='card-icon-container'>
                  <a
                    href='####'
                    target='_blank'
                    rel='noreferrer'
                    className='card-icon'
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <FontAwesomeIcon
                      icon={faGithub}
                      style={{ color: 'black', fontSize: 30 }}
                    />
                  </a>
                </div>
              </Card>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{ display: 'flex' }}
              id='col-card'
            >
              <Card id='card-block'>
                <CardImg src={movieAngular} className='img-fluid' />
                <Card.Title style={{ textAlign: 'center' }} id='title-text'>
                  Movie-Findr - Angular
                </Card.Title>
                <Card.Text style={{ textAlign: 'center' }} id='sub-text'>
                  An Angular front-end client using the movie-findr API
                </Card.Text>
                <div className='card-icon-container'>
                  <a
                    href='https://jarengisner.github.io/angular-movie-findr/welcome'
                    target='_blank'
                    rel='noreferrer'
                    className='card-icon'
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <FontAwesomeIcon
                      icon={faGlobe}
                      style={{ color: 'black', fontSize: 30 }}
                    />
                  </a>
                  <a
                    href='https://github.com/jarengisner/angular-movie-findr'
                    target='_blank'
                    rel='noreferrer'
                    className='card-icon'
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <FontAwesomeIcon
                      icon={faGithub}
                      style={{ color: 'black', fontSize: 30 }}
                    />
                  </a>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>

      <FooterComponent />
    </>
  );
};
