import { Container, Row } from 'react-bootstrap';
import '../../index.css';

import { NavigationBar } from '../navigation-component/navigation-component';
import { FooterComponent } from '../footer-component/footer-component';

const HomeView = () => {
  return (
    <Container>
      <Row>
        <NavigationBar />
      </Row>
      <Row className='main-home'>
        <div
          className='testing-container'
          style={{ marginTop: 0 }}
          id='home-text'
        >
          <h1 className='secondary-text'>
            <span className='hi'>Hi,</span> my name is
          </h1>
          <h1 className='secondary-text'>Jaren Gisner</h1>
          <p className='secondary-text'>I am passionate about building!</p>
        </div>
      </Row>
      <Row>
        <FooterComponent />
      </Row>
    </Container>
  );
};

export default HomeView;
