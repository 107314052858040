import '../../index.css';

//fontawesome icon imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

export const FooterComponent = () => {
  return (
    <footer
      className='text-center text-white fixed-bottom'
      style={{ background: 'black' }}
    >
      <div className='container p-4 pb-0'>
        <section className='mb-4'>
          <a
            className='btn-floating btn-outline-dark btn-floating m-1'
            href='https://github.com/jarengisner'
            rel='noreferrer'
            target='_blank'
            role='button'
          >
            <FontAwesomeIcon
              icon={faGithub}
              style={{ color: 'white', fontSize: 30 }}
              className='footer-icon'
            />
          </a>
          <a
            className='btn-floating btn-outline-dark btn-floating m-1'
            href='https://www.linkedin.com/in/jaren-gisner-800bb2265/'
            rel='noreferrer'
            target='_blank'
            role='button'
          >
            <FontAwesomeIcon
              icon={faLinkedin}
              style={{ color: 'white', fontSize: 30 }}
              className='footer-icon'
            />
          </a>
        </section>
      </div>
    </footer>
  );
};
