import '../../index.css';
//bootstrap imports
import { Container, Row } from 'react-bootstrap';

//component import
import { NavigationBar } from '../navigation-component/navigation-component';
import { FooterComponent } from '../footer-component/footer-component';

export const ContactComponent = () => {
  /* <h1 className='secondary-text'>Jaren Gisner</h1>
          <h1 className='secondary-text'>Portland, OR</h1>
          <h1 className='secondary-text'>jarengisnerdevelopment@gmail.com</h1>
          <h1 className='secondary-text'>970-261-5553</h1> */

  //586
  return (
    <Container>
      <Row>
        <NavigationBar />
      </Row>
      <Row style={{ marginTop: 80 }}>
        <h1 className='secondary-text'>Jaren Gisner</h1>
        <p className='secondary-text'>jaren.gisner@gmail.com</p>
        <p className='secondary-text'>970-261-5553</p>
        <p className='secondary-text'>Portland, OR</p>
      </Row>
      <Row id='google-form-row'>
        <iframe
          src='https://docs.google.com/forms/d/e/1FAIpQLScw0eoCtdRfrzWm9lbNivovAuOMSsxtQd53fl9OeHII14lP2w/viewform?embedded=true'
          title='contact'
          width='640'
          height='600'
          frameborder='0'
          marginheight='0'
          marginwidth='0'
          scrolling='no'
        >
          Loading…
        </iframe>
      </Row>
      <Row>
        <FooterComponent />
      </Row>
    </Container>
  );
};
