import './App.css';

//external package imports
import { Container } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';

//component imports
import HomeView from './components/home-component/home-component';
import { AboutComponent } from './components/about-component/about-component';
import { ContactComponent } from './components/contact-component/contact-component';
import { ProjectsPage } from './components/projects-component/projects-component';

function App() {
  return (
    <Container fluid className='main-container'>
      <Routes>
        <Route path='/' element={<HomeView />} />
        <Route path='/about' element={<AboutComponent />} />
        <Route path='/contact' element={<ContactComponent />} />
        <Route path='/projects' element={<ProjectsPage />} />
      </Routes>
    </Container>
  );
}

export default App;
