import { Col, Container, Row } from 'react-bootstrap';
import '../../index.css';
import { NavigationBar } from '../navigation-component/navigation-component';
import { FooterComponent } from '../footer-component/footer-component';

//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareJs } from '@fortawesome/free-brands-svg-icons';
import { faHtml5 } from '@fortawesome/free-brands-svg-icons';
import { faCss3Alt } from '@fortawesome/free-brands-svg-icons';
import { faBootstrap } from '@fortawesome/free-brands-svg-icons';
import { faNodeJs } from '@fortawesome/free-brands-svg-icons';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { faAws } from '@fortawesome/free-brands-svg-icons';
import { faReact } from '@fortawesome/free-brands-svg-icons';

export const AboutComponent = () => {
  return (
    <Container>
      <Row>
        <NavigationBar />
      </Row>
      <Row className='main-text' id='about-row-main'>
        <div className='testing-container'>
          <h1 className='secondary-text'>My name is Jaren Gisner</h1>
          <p className='secondary-text'>
            I am a recent bootcamp graduate with a passion for building
            <br />
            I enjoy working with all things technology and coming up with
            creative
            <br />
            solutions to problems. I enjoy problem solving, researching
            technology, and the <br />
            process of working my way towards a solution to a problem.
            <br />I am eager to learn any new technologies and bring my skills
            to the table
          </p>
        </div>
      </Row>
      <Row id='about-row-text'>
        <div>
          <h1 className='secondary-text'>Skills</h1>
          <p className='secondary-text'>
            Below are some of the technologies I have used to build projects in
            the past:
          </p>
        </div>
      </Row>
      <Row className='justify-content-center' id='about-row'>
        <Col xs={12} sm={12} md={4} lg={4} id='about-column'>
          <FontAwesomeIcon icon={faReact} style={{ fontSize: 60 }} />
          React
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} id='about-column'>
          <FontAwesomeIcon icon={faSquareJs} style={{ fontSize: 60 }} />
          Javascript
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} id='about-column'>
          <FontAwesomeIcon icon={faHtml5} style={{ fontSize: 60 }} />
          HTML
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} id='about-column'>
          <FontAwesomeIcon icon={faCss3Alt} style={{ fontSize: 60 }} />
          CSS
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} id='about-column'>
          <FontAwesomeIcon icon={faBootstrap} style={{ fontSize: 60 }} />
          Bootstrap
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} id='about-column'>
          <FontAwesomeIcon icon={faNodeJs} style={{ fontSize: 60 }} />
          NodeJS
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} id='about-column'>
          <FontAwesomeIcon icon={faCode} style={{ fontSize: 60 }} />
          Express
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} id='about-column'>
          <FontAwesomeIcon icon={faAws} style={{ fontSize: 60 }} />
          AWS
        </Col>
      </Row>
      <Row>
        <FooterComponent />
      </Row>
    </Container>
  );
};
